@import url(./font.css);

* {
  box-sizing: border-box;
  line-height: normal;
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
}
.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}
.common-pointer {
  cursor: pointer;
}
.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}
.dhiwise-navigation h1 {
  color: white;
}
.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}
.dhiwise-navigation ul {
  padding: 0;
}
.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.dhiwise-navigation a {
  color: #132cdc;
}
table {
  border-spacing: 0;
  width: 100%;
}
.common-select {
  z-index: 10000;
}
.react-datepicker-popper {
  z-index: 100000 !important;
}
.drawable-icon{
  position: absolute;
  margin: auto;
  z-index: 1000;
}
.input-wrap{
  position: relative;
}
option{
  color: #000;
}
.table-wrap{
  overflow: auto;
}
input:focus{
  outline:none;
}
.ReactModal__Body--open{
  overflow: hidden;
}
  .slider-indicator:first-child {
    margin-left: 0;
}

.slider-indicator:last-child {
margin-right: 0;
}

.rc-drawer-content-wrapper{
  width: 100% !important;
}
.rc-drawer-right .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-end;
}
.rc-drawer-left .rc-drawer-content-wrapper{
  display: flex;
  justify-content: flex-start;
}
.ReactModal__Overlay{
  z-index: 1051;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
  animation: fadeOut 0.4s ease-in-out;
}

ul li a{
  display: block;
}

.mobile-menu div{
  height: 1.5px;
  width: 20px;
  background-color: transparent;
  margin:4px 0;
}
.mobile-menu {
  display: none;
  cursor: pointer;
}
.header-row{
  align-items:center;
  justify-content: space-between;
  display: flex;
}
@media only screen and (min-width: 200px) and (max-width: 550px)  {
  .mobile-menu {
    display: block;
  }
  .header-row{
    width: 100% !important;
  }
  .display-block{
    display: block;
  }
  .display-none{
    display: none;
  }
  .common-row-list > ul{
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
  .coming-soon {
    background-position-x: 73%;
    color: white;
  }
}
.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}
.menu-overlay{
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}
.close-line1{
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.close-line2{
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}
.common-row-list > ul{
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list > ul{
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li{
  width: 100%;
}

.insurance-info tr > td:nth-child(2) {
  background-color: rgba(254, 249, 195, 0.6);
  height: 100%;
}

.compare-table tr > td {
  border-right: 1px solid rgba(203, 213, 225, 0.8);
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
.arrow-plan {
  fill: white;
}
.arrow-plan:hover {
  fill: rgba(35, 64, 146, 0.8);
}

.coming-soon {
  background-image: url('/public/images/img_desktopthree.png');
  background-repeat: no-repeat;
}

.compare-modal {
  transition: all ease-in-out 300ms;
}

.react-modal-content {
  animation: fadeIn 0.4s ease-in-out;
}

.react-modal-content.react-modal-content-exit {
  animation: fadeOut 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

.exclusions-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.exclusions {
  display: flex;
  flex-direction: column;
  max-width: 680px;
}
.exclusions-list {
  list-style: decimal;
  padding-left: 16px;
  cursor: default;
}
.exclusions-list > li {
  line-height: 60px;
  cursor: default;
}
.exclusions-list > li > p {
  cursor: default;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start 20px below its normal position */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Reach its normal position */
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0); /* Start at its normal position */
  }
  to {
    opacity: 0;
    transform: translateY(20px); /* Move 20px below its normal position */
  }
}