:root { 
--gray_50_01:#f5f9ff; 
--blue_gray_50:#eaedf2; 
--red_700:#e61c23; 
--blue_gray_100_01:#cbcfd6; 
--blue_gray_100_02:#d9d9d9; 
--gray_50:#f5faff; 
--red_50:#ffeaea; 
--black_900_1e:#0000001e; 
--deep_purple_A700_14:#3300ff14; 
--black_900:#000000; 
--blue_gray_900_01:#1d2639; 
--deep_purple_A200:#9747ff; 
--blue_gray_900:#343330; 
--purple_800:#6627a6; 
--deep_purple_A700:#3300ff; 
--black_900_49:#00000049; 
--gray_600:#777e88; 
--gray_700:#545961; 
--black_900_0a:#0000000a; 
--gray_600_01:#838383; 
--blue_gray_100:#cbcfd5; 
--gray_800:#383b40; 
--gray_100:#f5f5f5; 
--orange_50:#fcf3d6; 
--indigo_900:#0a205f; 
--white_A700:#ffffff; 
--indigo_800:#234092; 
--indigo_800_30:#23409230; 
--indigo_600:#39539d; 
--indigo_8001:#23409216;
}